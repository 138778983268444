import request from '../request'

export function fetchList(params) {
    return request({
        url: '/user-service/role/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/user-service/role/add',
        method: 'POST',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/user-service/role/id',
        params
    })
}

export function edit(data) {
    return request({
        url: '/user-service/role/update',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/user-service/role/delete',
        method: 'POST',
        data
    })
}