<template>
  <div>
    <div style="position: relative; z-index: 88">
      <Pane :bottom="0">
        <a-tabs
          v-model="selectedKey"
          :tabBarStyle="{
            margin: '0',
            borderBottom: 'none',
          }"
        >
          <a-tab-pane key="1" tab="基本信息"> </a-tab-pane>
          <a-tab-pane key="2" tab="分配角色"> </a-tab-pane>
          <a-tab-pane key="3" tab="数据权限"> </a-tab-pane>
        </a-tabs>
      </Pane>
    </div>

    <div style="margin-top: -44.8px; position: relative; z-index: 1">
      <a-tabs v-model="selectedKey">
        <a-tab-pane key="1" tab="">
          <a-card class="container" style="padding: 24px 160px">
            <a-form
              :form="form"
              @submit="handleSubmit"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
              :colon="false"
            >
              <a-row>
                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="用户名">
                    <a-input
                      v-decorator="[
                        'name',
                        { rules: [{ required: true, message: '请输入！' }] },
                      ]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="姓名">
                    <a-input
                      v-decorator="[
                        'userName',
                        { rules: [{ required: true, message: '请输入！' }] },
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="手机号码">
                    <a-input
                      v-decorator="[
                        'mobile',
                        {
                          rules: [{ required: required, message: '请输入！' }],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="邮箱">
                    <a-input
                      v-decorator="[
                        'email',
                        {
                          rules: [{ required: required, message: '请输入！' }],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="部门">
                    <a-select
                      v-decorator="[
                        'deptId',
                        {
                          rules: [
                            { required: true, message: '请输入后选择！' },
                          ],
                        },
                      ]"
                      show-search
                      :filter-option="$selectFilterOption"
                    >
                      <a-select-option
                        v-for="item in organizationList"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.uniqueName }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="用户状态">
                    <a-radio-group
                      @change="handleStatusChange"
                      v-decorator="[
                        'status',
                        { rules: [{ required: true, message: '请选择！' }] },
                      ]"
                    >
                      <a-radio
                        v-for="item in statusList"
                        :key="item.value"
                        :value="item.value"
                        >{{ item.name }}</a-radio
                      >
                    </a-radio-group>
                  </a-form-item>
                </a-col>

                <a-col :lg="24" :md="24" :sm="24">
                  <a-form-item
                    label="描述"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-textarea
                      :auto-size="{ minRows: 3 }"
                      v-decorator="['remarks']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <div class="center">
                <a-space>
                  <a-button html-type="submit" type="primary">保存</a-button>
                  <a-button @click="$close($route.path)">关闭</a-button>
                </a-space>
              </div>
            </a-form>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="2" tab="">
          <a-card class="container">
            <a-table
              bordered
              :dataSource="roleList"
              :row-selection="{
                selectedRowKeys: selectedRoleKeys,
                onChange: onSelectRoleChange,
              }"
              :pagination="{
                total: roleList.length,
                showTotal: (total) => `共 ${total} 条记录`,
              }"
            >
              <a-table-column key="key" title="key" data-index="key" />
              <a-table-column key="code" title="编码" data-index="code" />
              <a-table-column key="name" title="名称" data-index="name" />
              <a-table-column key="remarks" title="描述" data-index="remarks" />
            </a-table>
            <div class="center">
              <a-space>
                <a-button @click="save" type="primary">保存</a-button>
                <a-button @click="$close($route.path)">关闭</a-button>
              </a-space>
            </div>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="3" tab="">
          <div class="panel" v-if="dataPermissionList.length > 0">
            <div class="menu">
              <div
                v-for="(item, index) in dataPermissionList"
                :key="item.key"
                class="item"
                :class="activeIndex === index ? 'active' : ''"
                @click="selectMenu(index)"
              >
                {{ item.name }}
              </div>
            </div>

            <div class="data">
              <div class="title">
                {{
                  activeIndex > -1 ? dataPermissionList[activeIndex].name : ""
                }}
              </div>

              <a-transfer
                :data-source="dataSource"
                :target-keys="targetKeys"
                :show-search="true"
                :filter-option="
                  (inputValue, item) =>
                    (item.title && item.title.indexOf(inputValue) !== -1) ||
                    (item.description &&
                      item.description.indexOf(inputValue) !== -1)
                "
                :show-select-all="false"
                @change="onChange"
              >
                <template
                  slot="children"
                  slot-scope="{
                    props: { filteredItems, selectedKeys },
                    on: { itemSelectAll, itemSelect },
                  }"
                >
                  <a-table
                    :data-source="filteredItems"
                    bordered
                    :row-selection="
                      getRowSelection({
                        selectedKeys,
                        itemSelectAll,
                        itemSelect,
                      })
                    "
                    :pagination="{
                      total: filteredItems.length,
                      showTotal: (total) => `共 ${total} 条记录`,
                    }"
                  >
                    <a-table-column
                      key="code"
                      title="编码"
                      data-index="description"
                      ellipsis="true"
                    />
                    <a-table-column
                      key="name"
                      title="名称"
                      data-index="title"
                      ellipsis="true"
                    />
                  </a-table>
                </template>
              </a-transfer>

              <div class="center">
                <a-space>
                  <a-button type="primary" @click="saveDataPermission"
                    >保存</a-button
                  >
                  <a-button @click="$close($route.path)">关闭</a-button>
                </a-space>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import difference from "lodash/difference";

import { edit, configDataPermission, fetchDetail } from "@/api/setting/user";
import { fetchList as fetchRoleList } from "@/api/setting/role";
import { fetchList as fetchDataPermissionList } from "@/api/setting/data-permission";
import { mapGetters } from "vuex";
import request from "@/api/request";
export default {
  name: "editUser",
  mixins: [organization],
  data() {
    return {
      selectedKey: "1",
      form: this.$form.createForm(this),
      user: {},
      roleList: [],
      selectedRoleKeys: [],

      dataPermissionList: [],
      activeIndex: null,

      dataSource: [],
      total: 0,
      targetKeys: [], // 穿梭框右侧内容

      selectedDataPermission: [], // 获取到用户已经选择了所有数据权限，当左侧菜单变化时，把已选中的默认渲染上去

      required: true, // 默认必填
    };
  },

  activated() {
    fetchRoleList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.roleList = res.list.map((item) => {
          return {
            ...item,
            key: item.uuid,
          };
        });
      }
    });

    const { query } = this.$route;
    const { uuid } = query || {};

    if (uuid && this.user.uuid !== uuid) {
      fetchDetail({
        uuid,
      }).then((res) => {
        const { deptId } = res.employeeVo || {};

        this.user = {
          uuid: res.uuid,
          name: res.name,
          mobile: res.mobile,
          email: res.email,
          status: String(res.status),
          remarks: res.remarks,
          roles: res.roles.map((item) => item.uuid),
          deptId,
        };
        this.form.setFieldsValue({
          name: res.name,
          userName: res.userName,
          mobile: res.mobile,
          email: res.email,
          status: String(res.status),
          remarks: res.remarks,
          deptId,
        });

        this.selectedRoleKeys = res.roles.map((item) => item.uuid);

        const detail = res;

        // 获取所有的数据权限种类列表
        fetchDataPermissionList({
          pageNum: 1,
          pageSize: 1000,
        }).then((res) => {
          if (Array.isArray(res.list)) {
            this.dataPermissionList = res.list.map((item) => {
              const ids = [];
              // 将用户的数据权限，保存下来
              if (Array.isArray(detail.userDataPermissions)) {
                // this.selectedDataPermission = res.userDataPermissions;
                detail.userDataPermissions.forEach((element) => {
                  if (element.dataPermission === item.code) {
                    ids.push(element.dataPermissionValue);
                  }
                });
              }

              return {
                ...item,
                key: item.uuid,
                selected: ids,
              };
            });

            if (res.list.length > 0 && typeof this.activeIndex !== "number") {
              this.activeIndex = 0;
            }
          }
        });
      });
    }
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("status");
    },
  },

  watch: {
    activeIndex(newIndex, oldIndex) {
      const item = this.dataPermissionList[newIndex];
      const oldItem = this.dataPermissionList[oldIndex];
      if (oldItem) {
        // 将旧的选中的放在之前的种类中，新的填充进 targetKeys
        oldItem.selected = this.targetKeys;
      }

      // 把用户已有的权限数据渲染出来
      this.targetKeys = item.selected;

      this.dataPermissionList = [...this.dataPermissionList];

      if (item.type === "url") {
        request({
          url: item.url,
          params: {
            pageNum: 1,
            pageSize: 1000,
          },
        }).then((res) => {
          if (Array.isArray(res)) {
            let arr = res.map((item) => {
              return {
                key: item.id,
                title: item.name,
                description: item.code,
                disabled: false,
              };
            });
            arr.unshift({
              key: "*",
              title: "全部",
              description: "*",
              disabled: false,
            });
            this.dataSource = arr;

            this.total = res.length;
          }
        });
      } else if (item.type === "dict") {
        const dictList = this.findDataDict(item.dict);
        this.dataSource = dictList.map((item) => {
          return {
            key: item.value,
            title: item.name,
            description: item.value,
            disabled: false,
          };
        });
        this.total = dictList.length;
      }
    },
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...this.user,
            ...values,
          };
          edit(params).then(() => {
            this.user = params;
          });
        }
      });
    },

    onSelectRoleChange(keys) {
      this.selectedRoleKeys = keys;
    },

    handleStatusChange(e) {
      if (e.target.value === "0") {
        this.required = false;
      } else {
        this.required = true;
      }
    },

    save() {
      if (!this.user.uuid) {
        this.$message.error("请填写用户基本信息！");
        return;
      }

      const params = {
        ...this.user,
        roles: this.selectedRoleKeys,
      };
      edit(params).then(() => {
        this.user = params;
      });
    },

    selectMenu(index) {
      this.activeIndex = index;
    },

    getRowSelection({ selectedKeys, itemSelectAll, itemSelect }) {
      return {
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },

        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },

    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },

    saveDataPermission() {
      if (!this.user.uuid) {
        this.$message.error("请填写用户基本信息！");
        return;
      }

      if (this.targetKeys.length === 0) {
        this.$message.error("请选择数据权限项");
        return;
      }

      this.dataPermissionList.splice(this.activeIndex, 1, {
        ...this.dataPermissionList[this.activeIndex],
        selected: this.targetKeys,
      });

      configDataPermission([
        ...this.dataPermissionList.map((item) => {
          return {
            userId: this.user.uuid,
            dataPermission: item.code,
            dataPermissionValueList: item.selected,
          };
        }),
      ]);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
  margin: 16px 8px;
}

.center {
  margin-top: 80px;
}

.panel {
  margin: 16px 8px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  .menu {
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: #fff;
    padding: 16px;
    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .item {
      cursor: pointer;
      font-size: 13px;
      line-height: 2em;
    }
    .active {
      color: #1890ff;
      font-weight: bold;
    }
  }
  .data {
    grid-column-start: 2;
    grid-column-end: 6;
    background-color: #fff;
    padding: 16px;
    .title {
      color: #1890ff;
      font-weight: bold;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #d9d9d9;
    }
  }
}
</style>
